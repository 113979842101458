import { render, staticRenderFns } from "./imagesUplad.vue?vue&type=template&id=c5bd4736&scoped=true&"
import script from "./imagesUplad.vue?vue&type=script&lang=js&"
export * from "./imagesUplad.vue?vue&type=script&lang=js&"
import style0 from "./imagesUplad.vue?vue&type=style&index=0&id=c5bd4736&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c5bd4736",
  null
  
)

export default component.exports
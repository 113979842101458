<template>
  <div>
    <b-modal
      v-model="imageModal"
      centered
      size="xl"
      title="Banner suratlarini yuklang"
      hide-footer
      no-close-on-backdrop
      @close="$emit('close', true)"
      @cancel="$emit('close', true)"
    >
      <template v-if="cropImageOpen">
        <CropImageUpload
          :banner-type="bannerType"
          @upload="uploadCroppedImg"
          @backStep="backStep"
        />
      </template>
      <template v-else>
        <div class="d-flex justify-content-center flex-wrap">
          <!-- Desktop Photo -->
          <div
            v-loading="loads.loading_desktop_url"
            class="text-center m-2"
          >
            <div class="banner-img-wrapper">
              <template v-if="bannerForm.desktop_url">
                <img
                  :src="storageUrl + bannerForm.desktop_url"
                  height="162"
                  width="162"
                  class="banner-img"
                  @click="openCropper('desktop_url')"
                >
              </template>
              <template v-else>
                <b-img
                  class="banner-img"
                  height="120"
                  :src="require('@/assets/images/avatars/banner-6.jpg')"
                  alt="Left image"
                  @click="openCropper('desktop_url')"
                />
              </template>
            </div>
            <span
              class="upload-change-label"
              @click="openCropper('desktop_url')"
            >
              <feather-icon
                icon="PlusSquareIcon"
                size="12"
              /> Desktop banner ko'rinishini yuklang
            </span>
            <feather-icon
              v-if="bannerForm.desktop_url"
              icon="Trash2Icon"
              class="text-danger"
              size="18"
              @click="deleteImg('desktop_url')"
            />
            <input
              ref="firstPassport"
              accept="image/jpeg, image/jpg, image/png"
              type="file"
              class="d-none"
              @change="(e) => onChangeFile(e, 'desktop_url', 'photo')"
            >
          </div>

          <!-- Mobile Photo -->
          <div
            v-loading="loads.loading_mobile_url"
            class="text-center m-2"
          >
            <div class="banner-mobile-img-wrapper">
              <template v-if="bannerForm.mobile_url">
                <img
                  :src="storageUrl + bannerForm.mobile_url"
                  height="162"
                  width="162"
                  class="banner-mobile-img"
                  @click="openCropper('mobile_url')"
                >
              </template>
              <template v-else>
                <b-img
                  class="banner-mobile-img"
                  height="120"
                  :src="require('@/assets/images/avatars/banner-6.jpg')"
                  alt="Left image"
                  @click="openCropper('mobile_url')"
                />
              </template>
            </div>
            <span
              class="upload-change-label"
              @click="openCropper('mobile_url')"
            >
              <feather-icon
                icon="PlusSquareIcon"
                size="12"
              /> Mobil banner ko'rinishini yuklang
            </span>
            <feather-icon
              v-if="bannerForm.mobile_url"
              icon="Trash2Icon"
              class="text-danger"
              size="18"
              @click="deleteImg('mobile_url')"
            />
            <input
              ref="mobileUpload"
              accept="image/jpeg, image/jpg, image/png"
              type="file"
              class="d-none"
              @change="(e) => onChangeFile(e, 'mobile_url', 'photo')"
            >
          </div>
        </div>
        <hr>
        <h5>Banner turlari</h5>
        <p><b>Mahsulot banneri </b> - marketplace`dagi mahsulot showiga yo'naltiriladi</p>
        <p><b>Ma`lum turdagi tovarlar ro`yxati </b> - bunda mahsulotlar ro'yhatiga yo'naltiradi. </p>
        <hr>

        <el-row
          class="mb-2"
          :gutter="20"
        >
          <el-col
            :md="8"
            :lg="6"
          >
            <label label="type_id">Banner turi</label>
            <v-select
              id="type_id"
              v-model="formData.type"
              :options="[{type:'detail',name:'Mahsulot banneri'},{type:'products', name:'Ma`lum turdagi tovarlar ro`yxati'}]"
              placeholder="Turi"
              label="name"
              :reduce="(option) => option.type"
            />
          </el-col>
          <el-col
            :md="8"
            :lg="6"
          >
            <label label="category_id">Категория </label>
            <v-select
              id="category_id"
              v-model="formData.parent0_category_id"
              :options="categoriesLevel0"
              :reduce="(option) => option.id"
              class="w-100"
              style="width: 100%"
              placeholder="Категория"
              label="name_uz"
              @input="parent0change($event)"
            />
          </el-col>
          <el-col
            v-if="formData.parent0_category_id"
            :md="8"
            :lg="6"
          >
            <label label="category_id1">Подкатегория </label>
            <v-select
              id="category_id1"
              v-model="formData.parent1_category_id"
              :options="categoriesLevel1"
              :reduce="(option) => option.id"
              class="w-100"
              label="name_uz"
              placeholder="Подкатегория"
              @input="parent1change($event)"
            />
          </el-col>
          <el-col
            v-if="formData.parent1_category_id"
            :md="8"
            :lg="6"
          >
            <label label="category_id2">Номенклатура </label>
            <v-select
              id="category_id2"
              v-model="formData.parent2_category_id"
              :options="categoriesLevel2"
              :reduce="(option) => option.id"
              placeholder="Номенклатура"
              class="w-100"
              label="name_uz"
              @input="parent2change($event)"
            />
          </el-col>
          <el-col
            v-if="formData.parent2_category_id && formData.type == 'detail'"
            :span="6"
          >
            <label label="category_id2">Mahsulot nomi </label>

            <v-select
              id="category_id2"
              v-model="formData.category_id"
              :options="categoriesLevel3"
              :reduce="(option) => option.id"
              label="name_uz"
            />
          </el-col>
          <el-col
            v-else-if="formData.parent2_category_id && formData.type == 'products'"
            :md="8"
            :lg="6"
          >
            <label label="v-phone-1">Mahsulot nomi (filterda izlash uchun)</label>
            <b-form-input
              id="v-phone-1"
              v-model="formData.category_name"
              placeholder="Nomi"
            />
          </el-col>
        </el-row>

        <!-- <label
          for="banner"
          class="font-weight-bolder fs16"
        >Banner URL'ini (Banner ustiga bosganda qayerga borishini)
          ko'rsating</label>
        <b-form-input
          id="banner"
          v-model="bannerForm.link"
          class="w-50"
          name="value"
          placeholder="https://salomhayot.uz/category/iphone"
        /> -->

        <label
          for="banner"
          class="font-weight-bolder fs16 mt-2"
        >O'rni (Banner nechanchi o'qinda chiqishini
          ko'rsating)</label>
        <b-form-input
          id="banner"
          v-model="bannerForm.order"
          name="value"
          class="w-25"
          placeholder="1"
        />
        <div class="mt-2 text-right">
          <b-button
            variant="primary"
            class="mr-1"
            @click="send"
          >
            Yuklash
          </b-button>
        </div>
      </template>
    </b-modal>

  </div>
</template>

<script>
import { showToast } from '@/utils/toast'
import { mapActions } from 'vuex'
import { BImg } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: { BImg, vSelect, CropImageUpload: () => import('./cropImagesUplad.vue') },
  props: {
    model: {
      type: Object,
      default: () => {},
    },
    bannersCount: {
      type: Number,
      default: () => 0,
    },
    uploadedPhotos: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      imageModal: true,
      cropImageOpen: false,
      bannerForm: {
        id: null,
        mobile_url: '',
        desktop_url: '',
        link: '',
        order: '',
        data: null,
      },
      bannerType: '',
      imageData: {
        product_category_id: null,
        photos: [
          {
            url: '',
            link: '',
            is_main: 1,
          },
        ],
      },
      categoriesLevel0: [],
      categoriesLevel1: [],
      categoriesLevel2: [],
      categoriesLevel3: [],
      formData: {
        type: '',
        category_id: null,
        parent0_category_id: null,
        parent1_category_id: null,
        parent2_category_id: null,
        category_name: '',
      },
      loads: {
        loading_desktop_url: false,
        loading_mobile_url: false,
      },
    }
  },
  computed: {
    storageUrl() {
      return `${process.env.VUE_APP_BASE_URL}storage/`
    },
    isHasNotUploaded() {
      return this.imageData.photos.some(p => p.url == '')
    },
  },
  mounted() {
    if (this.uploadedPhotos && this.uploadedPhotos.length) {
      this.imageData.photos = this.uploadedPhotos
    }
    if (this.bannersCount) {
      this.bannerForm.order = this.bannersCount + 1
    }
    if (this.model.id) {
      this.bannerForm = this.model
    }
    this.getCategoriesByLevel(0) // Add this to load initial categories
  },
  methods: {
    ...mapActions({
      fileUpload: 'client/fileUpload',
      addBanner: 'shop/store',
      updateBanner: 'shop/update',
      getCategories: 'productCategory/index', // Add this action
    }),
    send() {
      if (!this.bannerForm.mobile_url || !this.bannerForm.desktop_url) {
        showToast('danger', 'Rasmni yuklang', 'XIcon')
      } else if (!this.bannerForm.order) {
        showToast('danger', 'O`rnini kiriting', 'XIcon')
      }
      // else if (!this.bannerForm.link) {
      //   showToast('danger', 'Link kiritilishi shart', 'XIcon')
      // }
      else {
        this.bannerForm.data = this.formData
        this.actionMethod(this.bannerForm).then(res => {
          showToast('success', this.$t('Успешно сохранено'), 'CheckCircleIcon')
          this.$emit('close', true)
        })
      }
    },
    actionMethod(data) {
      if (this.model.id) {
        return this.updateBanner(data)
      }
      return this.addBanner(data)
    },
    addImage() {
      if (this.imageData.photos.length < 5 && !this.isHasNotUploaded) {
        this.imageData.photos.push({ url: '', is_main: 0 })
      }
    },
    setImage(e) {
      const file = e.target.files[0]

      if (file.type.indexOf('image/') === -1) {
        showToast('danger', 'Iltimos jpg, png formatda rasm yuklang', 'XIcon')
        return
      }

      if (typeof FileReader === 'function') {
        const reader = new FileReader()

        reader.onload = event => {
          this.imgSrc = event.target.result
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result)
        }

        reader.readAsDataURL(file)
      } else {
        showToast('danger', 'Sorry, FileReader API not supported', 'XIcon')
      }
    },
    async openCropper(type) {
      this.bannerType = type
      this.cropImageOpen = true
    },
    async onChangeFile(event, type, directory) {
      if (event.target.files[0].size <= 15360000) {
        this.loads[`loading_${type}`] = true
        const fileData = new FormData()
        fileData.append('file', event.target.files[0])
        fileData.append('directory', directory)
        this.fileUpload(fileData)
          .then(res => {
            this.bannerForm[type] = res.data.path
            this.bannerForm.type = type
            this.cropImageOpen = true
          })
          .catch(err => {
            showToast('danger', err.data.message, 'XIcon')
          })
          .finally(() => {
            this.loads[`loading_${type}`] = false
          })
      } else {
        showToast(
          'danger',
          this.$t('Файл ҳажми 15 МБ дан кичик бўлиши лозим'),
          'XIcon',
        )
      }
    },
    deleteImg(type) {
      this.$bvModal
        .msgBoxConfirm(this.$t('Вы действительно хотите удалить?'), {
          title: this.$t('Подтвердите'),
          size: 'sm',
          variant: 'warning',
          okVariant: 'primary',
          okTitle: this.$t('Да'),
          cancelTitle: this.$t('Нет'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.bannerForm[type] = ''
            showToast('success', this.$t('Успешно удалено'))
          }
        })
    },
    uploadCroppedImg(path) {
      this.bannerForm[this.bannerType] = path
      console.log(path, this.bannerForm[this.bannerType], this.bannerType)
      this.cropImageOpen = false
    },
    backStep() {
      this.cropImageOpen = false
    },
    async parent0change(event) {
      this.formData.parent1_category_id = null
      this.formData.parent2_category_id = null
      await this.getCategoriesByLevel(1, event)
    },

    parent1change(event) {
      this.formData.parent2_category_id = null
      this.getCategoriesByLevel(2, event)
    },

    parent2change(event) {
      this.getCategoriesByLevel(3, event)
    },

    getCategoriesByLevel(level, parentId = null) {
      this.getCategories({ level, parent_id: parentId, per_page: 9999 })
        .then(res => {
          this[`categoriesLevel${level}`] = res.data.data
        })
        .catch(error => {
          showToast('danger', 'Error loading categories', 'XIcon')
        })
    },
  },
}
</script>

<style scoped lang="scss">
.banner-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
  display: block;
  border: 1px solid rgba(30, 30, 30, 0.3);
  margin: 0 auto 1rem;

  &-wrapper {
    width: 1058px;
    height: 352px;
  }
}

.banner-mobile-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
  display: block;
  border: 1px solid rgba(30, 30, 30, 0.3);
  margin: 0 auto 1rem;

  &-wrapper {
    width: 350px;
    height: 350px;
  }
}
</style>
